/* --Global-css----  */
.skeleton-container .skeleton {
  border-radius: 4px;
    background: linear-gradient(
      90deg,
      #e0e0e0 25%,
      #f5f5f5 50%,
      #e0e0e0 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;
  }

@keyframes shimmer {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
  

/* ---banner-section---- */

.skeleton-container .text-banner-badge.hero {
  height: 24px;
  max-width: 200px;
  width: 100%;
}

.skeleton-container .main-heading.hero {
  height: 101px;
  max-width: 586px;
  width: 100%;
}
.skeleton-container .pera.hero {
  height: 130px;
  max-width: 586px;
  width: 100%;
}
.skeleton-container .btn-skeleton.hero {
  height: 34px;
  max-width: 200px;
  width: 100%;
}
.skeleton-container .play-skeleton.hero {
  height: 32px;
  display: inline-block;
  max-width: 32px;
  width: 100%;
  border-radius: 50%;
}
.skeleton-container .video.hero {
  height: 360px;
  max-width: 636px;
  width: 100%;
}
.skeleton-container .hero-btn-container {
  max-width: 145px;
  width: 100%;
}

