
.stellarnav {
    position: relative;
    width: 100%;
    z-index: 9900;
    line-height: normal;
}
.stellarnav a {
    color: #777;
}
.stellarnav ul {
    margin: 0;
    padding: 0;
    text-align: right;
}
.stellarnav li {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    line-height: normal;
    vertical-align: middle;
}
.stellarnav li a {
    padding: 8px 16px;
    display: block;
    text-decoration: none;
    font-size: inherit;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    font-size: 16px;
}

/* main level */
.stellarnav > ul > li {
    display: inline-block;
}
.stellarnav > ul > li > a {
    color: #000;
    font-weight: 500;

}



.stellarnav.desktop li.has-sub ul li a {
    /* margin: 12px; */
    border-radius: 4px;
    padding: 15px 12px;
    color: #fff;
    border-bottom: 1px dashed rgb(255 245 220);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.stellarnav.desktop li.has-sub ul li:nth-last-child(1) a{
    border-bottom: 0;
}



.stellarnav ul li ul li:hover{
    background: var(--color-2);
}

.stellarnav ul li a:hover {
    color: #000 !important;
}


/* first level dd */
.stellarnav ul ul {
    top: auto;
    width: 220px;
    position: absolute;
    z-index: 9900;
    text-align: left;
    display: none;
    background: #000;
    border-top: 3px solid #fff5dc;
    box-shadow: 0px 20px 54px -21px #000a6;
}
.stellarnav li li {
    display: block;
}

/* second level dd */
.stellarnav ul ul ul {
    top: 0; /* dd animtion - change to auto to remove */
    left: 220px;
}
.stellarnav > ul > li:hover > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 0;
}

/* .drop-left */
.stellarnav > ul > li.drop-left > ul {
    right: 0;
}
.stellarnav li.drop-left ul ul {
    left: auto;
    right: 220px;
}

/* light theme */
.stellarnav.light {
    background: rgba(255, 255, 255, 1);
}
.stellarnav.light a {
    color: #000;
}
.stellarnav.light ul ul {
    background: rgba(255, 255, 255, 1);
}
.stellarnav.light li a {
    color: #000;
}

/* sticky nav */
.stellarnav.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

/* only used when 'scrollbarFix' is set to true in the js. This fixes horizontal scrollbar caused by the dd menus that are very long.*/
body.stellarnav-noscroll-x {
    overflow-x: hidden;
}

/* general styling */

.stellarnav li.has-sub>a:after {
    content: "\f282";
    position: absolute;
    font-size: 12px;
    top: 50%;
    right: 0px;
    font-family: bootstrap-icons !important;
    color: #000;
    z-index: 1;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

.stellarnav > ul > li:hover > a:after{
    transform: rotate(180deg) translateY(50%);
}

.stellarnav li.has-sub> ul li a::after{
    color: #fff;
    transform: translateY(-50%) rotate(270deg);
}

.stellarnav.hide-arrows li.has-sub > a:after,
.stellarnav.hide-arrows li li.has-sub > a:after,
.stellarnav.hide-arrows li.drop-left li.has-sub > a:after {
    display: none;
}
.stellarnav .menu-toggle,
.stellarnav .close-menu,
.stellarnav .call-btn-mobile,
.stellarnav .location-btn-mobile {
    display: none;
    text-transform: uppercase;
    text-decoration: none;
}
.stellarnav .dd-toggle {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 48px;
    height: 48px;
    text-align: center;
    z-index: 9999;
    border: 0;
}
.stellarnav.desktop.hide-arrows li.has-sub a {
    padding-right: 15px;
}
.stellarnav.mobile > ul > li > a.dd-toggle {
    padding: 0;
}
.stellarnav li.call-btn-mobile,
.stellarnav li.location-btn-mobile {
    display: none;
}

/* svg icons */
.stellarnav svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
    position: relative;
    top: 2px;
}
/* svg icons */

.stellarnav a.dd-toggle .icon-plus {
    box-sizing: border-box;
    transition: transform 0.3s;
    width: 12px;
    height: 100%;
    position: relative;
    vertical-align: middle;
    display: inline-block;
}
.stellarnav a.dd-toggle .icon-plus:before {
    content: "";
    display: block;
    width: 12px;
    height: 0px;
    border-bottom: solid 3px #fff;
    position: absolute;
    top: 50%;
    transform: rotate(90deg);
    transition: width 0.3s;
}
.stellarnav a.dd-toggle .icon-plus:after {
    content: "";
    display: block;
    width: 12px;
    height: 0px;
    top: 50%;
    border-bottom: solid 3px #fff;
    position: absolute;
}
.stellarnav li.open > a.dd-toggle .icon-plus {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.stellarnav.light a.dd-toggle .icon-plus:before {
    border-color: #000;
}
.stellarnav.light a.dd-toggle .icon-plus:after {
    border-color: #000;
}
.stellarnav.dark a.dd-toggle .icon-plus:before {
    border-color: #fff;
}
.stellarnav.dark a.dd-toggle .icon-plus:after {
    border-color: #fff;
}

.full{
    margin-bottom: 30px;
}

.stellarnav .icon-close {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stellarnav .icon-close:before {
    content: "";
    display: block;
    width: 20px;
    height: 0px;
    border-bottom: solid 3px #777;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
}
.stellarnav .icon-close:after {
    content: "";
    display: block;
    width: 20px;
    height: 0px;
    top: 50%;
    border-bottom: solid 3px #777;
    position: absolute;
    transform: rotate(-45deg);
}
.stellarnav.light .icon-close:before {
    border-color: #000;
}
.stellarnav.light .icon-close:after {
    border-color: #000;
}
.stellarnav.dark .icon-close:before {
    border-color: #fff;
}
.stellarnav.dark .icon-close:after {
    border-color: #fff;
}

/* mobile nav */
.stellarnav .menu-toggle,
.stellarnav .call-btn-mobile,
.stellarnav .location-btn-mobile,
.stellarnav .close-menu {
    padding: 15px;
    box-sizing: border-box;
}
.stellarnav .menu-toggle span.bars {
    display: inline-block;
    position: relative;
    top: 3px;
}
.stellarnav .menu-toggle span.bars span {
    display: block;
    width: 28px;
    height: 4px;
    border-radius: 6px;
    background: #FFf;
    margin: 0 0 4px;
}
.stellarnav .full {
    width: 100%;
}
.stellarnav .half {
    width: 50%;
}
.stellarnav .third {
    width: 33%;
    text-align: center;
}
.stellarnav .location-btn-mobile.third {
    text-align: center;
}
.stellarnav .location-btn-mobile.half {
    text-align: right;
}
.stellarnav.light .third,
.stellarnav.light .half {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.stellarnav.light.left .third,
.stellarnav.light.left .half {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.stellarnav.light.right .third,
.stellarnav.light.right .half {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.stellarnav.light .third:first-child,
.stellarnav.light .half:first-child {
    border-left: 0;
}
.stellarnav.dark .third,
.stellarnav.dark .half {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.stellarnav.dark.left .third,
.stellarnav.dark.left .half {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.stellarnav.dark.right .third,
.stellarnav.dark.right .half {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.stellarnav.light.left .menu-toggle,
.stellarnav.light.right .menu-toggle {
    border-bottom: 0;
}
.stellarnav.dark.left .menu-toggle,
.stellarnav.dark.right .menu-toggle {
    border-bottom: 0;
}
.stellarnav.dark .third:first-child,
.stellarnav.dark .half:first-child {
    border-left: 0;
}
.stellarnav.light .menu-toggle span.bars span {
    background: #000;
}
.stellarnav.dark .menu-toggle span.bars span {
    background: #fff;
}
.stellarnav.mobile {
    position: static;
    text-align: right;
}
.stellarnav.mobile.fixed {
    position: static;
}
.stellarnav.mobile ul {
    position: relative;
    display: none;
}
.stellarnav.mobile.active {
    padding-bottom: 0;
}
.stellarnav.mobile.active > ul {
    display: block;
}
.stellarnav.mobile ul {
    text-align: left;
}
.stellarnav.mobile > ul > li {
    display: block;
}
.stellarnav.mobile > ul > li > a {
    padding: 15px;
    color: #fff;
}
.stellarnav.mobile ul {
    background: #2582f7;
}
.stellarnav.mobile ul ul {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: auto;
    display: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
    background-color: #ca6c39;
}
.stellarnav.mobile ul ul ul {
    left: auto;
    top: auto;
    background-color: #a34f21;
}
.stellarnav.mobile li.drop-left ul ul {
    right: auto;
}
.stellarnav.mobile li a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    color: #fff;
}
.stellarnav.mobile > ul {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.stellarnav.mobile.light li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.stellarnav.mobile.light > ul {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.stellarnav.mobile li a.dd-toggle {
    border: 0;
}
.stellarnav.mobile.light li a.dd-toggle {
    border: 0;
}
.stellarnav.mobile .menu-toggle,
.stellarnav.mobile .dd-toggle,
.stellarnav.mobile .close-menu,
.stellarnav.mobile .call-btn-mobile,
.stellarnav.mobile .location-btn-mobile {
    display: inline-block;
}
.stellarnav.mobile li.call-btn-mobile {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}
.stellarnav.mobile li.call-btn-mobile,
.stellarnav.mobile li.location-btn-mobile {
    display: inline-block;
    width: 50%;
    text-transform: uppercase;
    text-align: center;
}
.stellarnav.mobile li.call-btn-mobile.full,
.stellarnav.mobile li.location-btn-mobile.full {
    display: block;
    width: 100%;
    text-transform: uppercase;
    border-right: 0;
    text-align: left;
}
.stellarnav.mobile.light ul {
    background: rgba(255, 255, 255, 1);
}
.stellarnav.mobile.dark ul {
    background: rgba(0, 0, 0, 1);
}
.stellarnav.mobile.dark ul ul {
    background: rgba(255, 255, 255, 0.08);
}
.stellarnav.mobile.light li.call-btn-mobile {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.stellarnav.mobile.top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}
.stellarnav.mobile li.has-sub > a:after,
.stellarnav.mobile li li.has-sub > a:after,
.stellarnav.mobile li.drop-left li.has-sub > a:after {
    display: none;
}

/* left and right positions */
.stellarnav.mobile.right > ul,
.stellarnav.mobile.left > ul {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 99;
}
.stellarnav.mobile.right > ul {
    right: 0;
}
.stellarnav.mobile.right .close-menu,
.stellarnav.mobile.left .close-menu {
    display: inline-block;
    text-align: right;
}
.stellarnav.mobile.left > ul {
    left: 0;
}
.stellarnav.mobile.right .location-btn-mobile.half,
.stellarnav.mobile.right .call-btn-mobile.half,
.stellarnav.mobile.right .close-menu.half {
    text-align: center;
}
.stellarnav.mobile.right .location-btn-mobile.third,
.stellarnav.mobile.right .call-btn-mobile.third,
.stellarnav.mobile.right .close-menu.third {
    text-align: center;
}
.stellarnav.mobile.left .location-btn-mobile.half,
.stellarnav.mobile.left .call-btn-mobile.half,
.stellarnav.mobile.left .close-menu.half {
    text-align: center;
}
.stellarnav.mobile.left .location-btn-mobile.third,
.stellarnav.mobile.left .call-btn-mobile.third,
.stellarnav.mobile.left .close-menu.third {
    text-align: center;
}
.stellarnav.mobile.left .menu-toggle.half,
.stellarnav.mobile.left .menu-toggle.third,
.stellarnav.mobile.right .menu-toggle.half,
.stellarnav.mobile.right .menu-toggle.third {
    text-align: left;
}
.stellarnav.mobile.left .close-menu.third span,
.stellarnav.mobile.right .close-menu.third span {
    display: none;
}
/* left and right positions */

/* mega dd */
.stellarnav.desktop li.mega ul ul {
    background: none;
}
.stellarnav.desktop li.mega li {
    display: inline-block;
    vertical-align: top;
    margin-left: -4px;
}
.stellarnav.desktop li.mega li li {
    display: block;
    position: relative;
    left: 4px;
}
.stellarnav.desktop li.mega ul ul {
    width: auto;
}
.stellarnav.desktop > ul > li.mega {
    position: inherit;
}
.stellarnav.desktop > ul > li.mega > ul {
    width: 100%;
}
.stellarnav.desktop > ul > li.mega > ul li.has-sub ul {
    display: block;
    position: relative;
    left: auto;
}
.stellarnav.desktop > ul > li.mega > ul > li {
    padding-bottom: 15px;
    box-sizing: border-box;
}
.stellarnav.desktop li.mega li li a {
    padding: 5px 15px;
}
.stellarnav.desktop li.mega li.has-sub a:after {
    display: none;
}
.stellarnav.desktop > ul > li.mega > ul > li > a {
    color: yellow;
}
/* mega dd */

/* Fallback for slow javascript load */
@media only screen and (max-width: 768px) {
    .stellarnav {
        overflow: hidden;
        display: block;
    }
    .stellarnav ul {
        position: relative;
        display: none;
    }
}

/* mobile nav */
@media only screen and (max-width: 420px) {
    .stellarnav.mobile .call-btn-mobile.third span,
    .stellarnav.mobile .location-btn-mobile.third span {
        display: none;
    }
}
