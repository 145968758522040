@media(max-width:1770px){
   
}

@media(max-width:1779px){
   
}

@media(max-width:1677px){
 
}

@media(max-width:1500px){
 
}



@media(max-width:1440px) {
    

}


@media(max-width:1400px) {


}

@media(max-width:1366px) {   

}

@media(max-width:1330px){

}

@media(max-width:1280px) {  
  
    
}



@media(max-width:1199px) {
    .stellarnav.mobile .menu-toggle{
        background-color: #2582f7;
    };
    }
    .top-header .container {
        max-width: 100%;
        padding: 0 0%;
    }

    .stellarnav li a{
        padding: 8px 18px;
    }
 


@media (max-width:1088px) {
 
}
@media (max-width:1024px) {
 
}


@media(max-width: 991px) {
   /* ------hero-section-------  */

   .banner-caption{
    padding-right: 0;
    margin-bottom: 30px;
   }
   .home-banner-section{
    padding-bottom: 100px;
   }

   /* ----why-choose-us----  */

   .item-why-us.one {
    padding-top: 60px;
    border-bottom: 1px solid var(--secondary-color);
    }
    .item-why-us {
       padding-bottom: 30px;
    }
    .item-why-us-right.border-theme{
        padding-left: 0;
        padding-top: 30px;
        border: none !important;
    }

    .why-use-img{
        height: auto;
    }

    /* -----content-service------- */
    .content-service {
        max-width: 100%;
    }
    .single-sevice-container .img-right.top{
        border: none;
        margin-bottom: 0;
    }
    .content-service.two{
        padding-top: 0;
    }

    /* ---podcast--items-------  */
    .podcast-item:nth-of-type(even) .row>div{
        order: 1;
        }
        .podcast-item:nth-of-type(even) .row>div + div{
        order: 2;
        }
        .podcast-item:nth-of-type(even) .banner-caption{
            padding-left: 0px;
        }
        
        /* --pagination----  */
        
        .pagination{
            padding-bottom: 30px;
        }

        /* --welcome-page---  */

        .right-side-form {
            padding-top: 0px;
        }
        .welcome-top .Presents {
            font-size: 20px;
        }
        .welcome-top .title {
            font-size: 50px;
        }
        .welcome-top .title .stylish {
            font-size: 80px;
        }
        .welcome-top .sub_heading {
            font-size: 26px;
        }
}

@media(max-width: 767px) {
    body,
    html {
        scroll-behavior: smooth;
        overflow-x: clip;
    }
    .header-main {
        background-color: #fff;
    }
    .header-main .logo {
        width: 60px;
        min-height: 60px;
    }
    .header-main .navbar-nav {
        gap: 0;
        border-top: 1px solid #8c52ff;
    }
    .header-main .navbar-nav li{
        padding-left: 10px;
    }
    .navbar-collapse{
        position: absolute;
        background: white;
        width: 100%;
        right: 0px;
        padding: 25px;
        top: 99%;
    }

    /* --breadcrumb-start--  */
    .breadcrumb{
        padding-top: 80px;
        padding-bottom: 10px; 
    }
    .breadcrumb .items li {
        margin: 0px 10px 0 0;
    }
    /* --breadcrumb-end--  */

     /* ------hero-section-------  */
    .home-banner-section {
        min-height: auto;
    padding-top: 100px;
    }
    h1,
    .h1 {
        font-size: 2rem;
    }
    
    h2,
    .h2 {
        font-size: 1.8rem;
    }
    
    h3,
    .h3 {
        font-size: 1.65rem;
    }
    
    h4,
    .h4 {
        font-size: 1.5rem;
      }

    .sec_padding{
        padding: 50px 0px;
    }
    
    .main-heading{
      font-size: 28px;
    }
    .main-heading-stylish{
      font-size: 36px;
    }

    .title {
        font-size: 30px;
    }
    .sub-title{
        font-size: 18px;
    }
    
    
    .sec_heading{
      font-size: 28px;
    }
    .title-pera-center {
        max-width: 90%;
        font-size: 16px;
    }
    .title-pera {
        font-size: 16px;
    }
    .sec_heading-stylish{
        font-size: 45px;
    }
    
    .sub_heading{
        font-size: 18px;
    }
    .pera{
        font-size: 14px;
    }

    .tp_btn {
        font-size: 14px;
    }
    .podcast-btn{
        font-size: 14px;
    }

    /* our-offring-  */

    .down-arrow{
        margin-top: 15px;
        font-size: 24px;
    }
    .padding-top-item {
        padding-top: 120px;
    }

    /* ----transformation---- */

    .tr-items{
        padding-top: 20px;
    }
    .tr-box.left{
        justify-content: center;
        align-items: center;
    }
    .tr-box.right{
        justify-content: center;
        align-items: center;
    }
    .tr-box .title-tr{
        text-align: center;
    }
    .tr-box.left .pera,
    .tr-box.right .pera {
        text-align: center;
    }
    .img-tr{
        max-width: 140px;
    }
    .tr-box{
        margin-bottom: 10px;

    }

    /* ---testimonial----  */

    .testimonial-slide {
        margin-top: 0px;
        margin-right: 0px;
    }
    .testimonial-slide .item-testimonial{
        margin-bottom: 20px;
        padding: 20px;
    }
    .img-user {
        width: 60px;
    }
    .user-name{
        font-size: 16px;
    }
    .destination{
        font-size: 12px;
        line-height: 1.2;
    }
    .rating-icon{
        font-size: 12px;
    }

    /* ----about----- */
    .inner-banner-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .podcast-item{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .inner-banner-section.about-top {
        padding-bottom: 120px;
    }
    /* ---step-process---- */

    .step-process .sub_heading {
        font-size: 24px;
    }
    .step {
        padding-left: 80px;
    }
    .step .title {
        font-size: 24px;
    }


 /* ---footer---- */
 h5.footer-heading {
    min-height: auto;
}

/* ------login----- */

.form_container{
    min-height: 100vh;
   }
    .form_container .row_form{
      border: none !important;
      box-shadow: none !important;
    }
    .form_bg{
        border-radius: 4px;
    }

    
/* ----page-not-found------ */

.page-not-found{
    padding-bottom: 160px;
    background: var(--gradient-color);
    min-height: calc(100vh - 132px);
}
  .error{
    font-size: 70px;
    text-shadow: 
      1px 1px 1px var(--secondary-color),    
      2px 2px 1px var(--secondary-color),
      3px 3px 1px var(--secondary-color),
      4px 4px 1px var(--secondary-color),
      15px 15px 8px rgba(0,0,0, 0.2);
  }
  
  .page{
    margin: 15px 0;
    font-size: 16px;
  }

  /* --welcome-page---  */

.welcome-top .Presents {
    font-size: 18px;
}
.welcome-top .title {
    font-size: 40px;
}
.welcome-top .title .stylish {
    font-size: 60px;
}
.welcome-top .sub_heading {
    font-size: 22px;
}
.welcome-regiter-form.contact-form {
    margin-bottom: 0px;
}

.right-side-form {
    padding-top: 20px;
}

 
   
}

@media(max-width: 680px){
    
}

@media(max-width: 525px){ 

    
  /* --welcome-page---  */

.welcome-top .Presents {
    font-size: 18px;
}
.welcome-top .title {
    font-size: 26px;
}
.welcome-top .title .stylish {
    font-size: 45px;
}
.welcome-top .sub_heading {
    font-size: 18px;
}
.welcome-regiter-form.contact-form {
    margin-bottom: 0px;
}
.session-information-box {
    padding: 20px
}
.img-unique-program{
    min-height: auto;
}
    .sec_padding{
        padding: 40px 0px;
    }
    
    .main-heading{
      font-size: 24px;
    }
    .main-heading-stylish{
      font-size: 32px;
    }
    
    
    .sec_heading{
      font-size: 24px;
    }
    .title-pera-center {
        max-width: 95%;
        font-size: 14px;
    }
    .title-pera {
        font-size: 14px;
    }
    .sec_heading-stylish{
        font-size: 40px;
    }
    
    .sub_heading{
        font-size: 16px;
    }
    .title {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .sub-title{
        font-size: 16px;
    }
    .pera{
        text-align: justify;
    }
    .banner-caption .pera{
        font-size: 14px;
    }


    /* our-offring-  */

    .service-item{
        padding: 25px 20px;
    }

    .box-details {
        margin-bottom: 10px;
    }
    .box-details {
        margin-top: -110px;
    }

    .Presents {
        margin-bottom: 15px;
    }

    /* ---step-process---- */

    .step-process .sub_heading {
        font-size: 20px;
    }
    .step {
        padding-left: 60px;
        padding-bottom: 20px;
    }
    .step .title {
        font-size: 20px;
    }
    .step-number {
        width: 32px;
        height: 32px;
    }
    .step::after {
        left: 16px;
    }

    /* ----why-choose-us----  */

   .item-why-us.one {
    padding-top: 30px;
    }
    .item-why-us {
       padding-bottom: 20px;
    }
    .item-why-us-right.border-theme{
        padding-top: 20px;
    }
    .item-why-us.three{
        padding-top: 20px;
    }
    .item-why-us .icon-why {
        width: 36px;
        height: 36px;
    }
    .item-why-us {
        gap: 15px;
    }

    /* ----------product-details---- */
    .prodcut-name {
        margin: 10px 0;
        font-size: 24px;
    }

    .testimonial-slide .item-testimonial{
        min-height: 320px;
    }

    .contact-form{
        margin-bottom: -10px;
    }

    
   
   
   
}


@media(max-width: 425px){ 
.searchbar {
    width: 100%;
}
  /* --main--form----- */
  .form_container .row_form {
    width: 98%;
  }
  .form_container .form_main {
    width: 100%;
    padding: 20px 20px;
    /* padding: 40px; */
  }
  .form_container .form_main {
    width: 100%;
    padding: 20px 20px;
  }
  .form_container form{
    padding: 0px;
  }
    
   
}

@media(max-width: 425px){ 
}
@media(max-width: 385px){ 

    /* ---login- */
  .form_container .form .heading h2{
    font-size: 25px;
  }
  .form_input .fa-circle-check, .form_input .fa-circle-exclamation {
    right: 15px !important;
    font-size: 14px !important;
  }
  .form_container .form .heading p{
    font-size: 10px !important;
  }
  .sb-checkbox__label{
    font-size: 10px !important;
  }
  .form_container .form span{
    font-size: 10px !important;
  }
 

    .podcast-text-none{
        display: none;
    }
}

