@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: "Raleway-Black";
    src: url(../font/tt_ramillas.woff);
  }

@font-face {
    font-family: "Gistesy";
    src: url(../font/gistesy-webfont.woff);
  }


:root {
    --primary-color: #2582f7;
    --secondary-color: #8c52ff;
    --accent-color: #d922d2;
    --gradient-color: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(37,130,247,0.1393732492997199) 97%);
    --text-color: rgb(84, 84, 84);
    --background-color: rgb(241, 241, 241);
    --color-white:#fff;
    --color-blck:#000;
    --box-shadow: 6px 12px 50px #27272729;
    --font-text: "Raleway", sans-serif;
    --font-stylish: "Gistesy";
    --font-heading: "ramillas";
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    /* font-family: Ramillas; */
}
.stellarnav > ul > li > a{
    font-family: var(--font-text);
}


ul, ol{
    padding: 0;
    margin: 0;
    list-style: none;
}

figure{
    margin: 0;
}

.link{
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: underline;
}


audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

summary {
    display: list-item;
}



body {
    line-height: 1.4;
}

p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
    font-family: var(--font-text);
    font-weight: 400;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none !important;
    transition: all 0.3s ease-in-out;
}

h1,
.h1 {
    font-size: 2.5rem;
     color: var(--primary-color);
}

h2,
.h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    font-weight: 600;
}

h3,
.h3 {
    font-size: 1.75rem;
    font-weight: 600;
}

h4,
.h4 {
    font-size: 1.5rem;
  }
h5,
.h5 {
    font-size: 1.25rem;
     font-weight: 600;
  }
h6,
.h6 {
    font-size: 1rem;
  }
  


.sec_padding{
    padding: 60px 0px;
}

.main-heading{
  position: relative;
  display:block;
  font-size: 36px;
  font-weight: 600;
  font-family: var(--font-heading);
  color: var(--primary-color);
  line-height: 1.4;
}
.main-heading-stylish{
  position: relative;
  display:block;
  font-family: var(--font-stylish);
  font-size: 40px;
  font-weight: 600;
  color: var(--primary-color);
  line-height: 1.4;
}


.sec_heading{
  position: relative;
  font-weight: 600;
  color: var(--primary-color);
  font-family: var(--font-heading);
  font-size: 36px;
}
.title-pera-center {
    max-width: 80%;
    text-align: center;
    margin: auto;
    font-size: 16px;
}
.title-pera {
    font-size: 16px;
}
.sec_heading-stylish{
    position: relative;
    font-weight: 500;
    color: var(--primary-color);
    font-family: var(--font-stylish);
    font-size: 52px;
}

.sub_heading{
    position: relative;
    font-weight: 450;
    color: #000;
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
}
.pera{
    position: relative;
    color: var(--text-color);
    display: block;
    line-height: 26px;
    font-family: var(--font-text);
    font-size: 14px;
}



a{
    color: #292929;
    transition: all 0.3s linear;
}



figure img{
    width: 100%;
}


.theme_btn{
    background-color: var(--secondary-color);
    display: inline-block;
    padding: 4px 32px;
    border-radius: 4px;
    color: var(--color-white);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease cubic-bezier(0.23, 1, 0.320, 1);
    z-index: 1;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
.theme_btn{
    background-color: var(--secondary-color);
    display: inline-block;
    padding: 4px 32px;
    border-radius: 4px;
    color: var(--color-white);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease cubic-bezier(0.23, 1, 0.320, 1);
    z-index: 1;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  
  .theme_btn:hover{
    box-shadow: 0 20px 30px -6px rgba(140, 82, 255, 0.5);
    color: var(--color-white);
    background-color: var(--secondary-color);
    transform: translateY(-2px);
  }
  
  .tp_btn{
    background-color: transparent;
    display: inline-block;
    padding: 4px 32px;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease cubic-bezier(0.23, 1, 0.320, 1);
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  
  .tp_btn:hover{
    background-color: var(--secondary-color);
    color: var(--color-white);
    border-color: var(--color-white);
    box-shadow: 0 20px 30px -6px rgba(140, 82, 255, 0.5);
    transform: translateY(-2px);
  }

  /* --modal-center---  */
   .modal-dialog{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
   }


  /* ---header------  */
.header-main{
    position: fixed;
    top: 0;
    left: 0;
}
.header-main.bg-header{
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
    box-shadow: var(--box-shadow);
}
.header-main{
    position: fixed;
    top: 0;
    left: 0;
}
.user-profile-icon{
    display: inline-block;
    width: 32px;
    margin-left: 10px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.user-profile-icon img{
    width: 100%;
    object-fit: cover;
}
.header-main .logo{ 
    width: 80px;
    min-height: 80px;
}
.header-main .logo img{ 
   width: 100%;
   vertical-align: middle;
}
.header-main .navbar{ 
    padding: 0;
}
.header-main .navbar-nav{ 
    gap: 15px;
}
.header-main .navbar-toggler:focus{ 
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-color);
}
.header-main .navbar-toggler{ 
    border: none;
}
.header-main .nav-link{ 
    font-size: 17px;
    font-weight: 550;
    color: var(--text-color);
    font-family: var(--font-heading);
    position: relative;
}
/* .header-main .nav-link::after{ 
   content: "";
   position: absolute;
   width: 0;
   height: 2px;
   transition: .3s;
   left: 0px;
   bottom: 0px;
   background-color: var(--secondary-color);
}
.header-main .nav-link:hover::after{ 
    width: 100%;
} */
.header-main .nav-link:hover,
.header-main .nav-link.active{ 
color: var(--secondary-color);
}
.header-main .theme_btn{ 
    max-height: 36px;
}


  /* ---------videos-icon-taskdigi-crm----  */
  
  @keyframes waves {
    0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
  }
  
 .waves {
    position: absolute;
    width: 48px;
    height: 48px;
    background: rgba(59, 61, 152, 0.637);
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    left: -9px;
    bottom: -6px;
    z-index: -1;
     -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
  }
  
 .wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
 .wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }


/* Header-start */
header { 
    position: fixed;
    width: 100%;
    z-index: 99;
    transition: all ease .2s;
}

.scrolled-header{
    box-shadow: 0px 4px 32px #f3f1f1;
    background-color: #fff;
}

.header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}


.header .logo a img {
   width: 80px;
}


.header p {
    margin: 10px 0 40px 0;
    font-size: 18px;
}

.stellarnav {
    display: flex;
    justify-content: end;
    align-items: end;
}


.top-header {
    background: var(--primary-color);
    padding: 12px 0;
}


.contact-dets ul{
    display: flex;
    gap: 8px;
    justify-content: center;
}

.contact-dets ul li{
    border-right: 1px solid #fff;
    padding: 0 18px;
}

.contact-dets ul li:nth-last-child(1){
    border-right: 0;
}


.contact-dets ul li a{
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-color);
}
.contact-dets ul li a i{
    margin-right: 10px;    
}

.main_header.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 10px 0 rgb(215 215 215 / 67%);
    box-shadow: 0 1px 10px 0 rgb(215 215 215 / 67%);
    -webkit-animation: 1s smoothScroll;
    animation: 1s smoothScroll;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 9999;
    padding: 22px 9%;
}

@-webkit-keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}




/* Header-Close */



/* ---footer-start------  */

footer{
    background-color: var(--background-color);
    padding-top: 40px;
}

.footer-logo{
    width: 100px;
}

h5.footer-heading {
    font-size: 18px;
    font-weight: 600;
    min-height: 53px;
    margin-bottom: 16px;

}

.footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;

}
.footer-links li {
    margin-bottom: 10px;
}
.footer-links li a{
    color: var(--text-color);
    font-family: var(--font-text);
    font-size: 16px;
}

.footer-wrapper .contact-dets ul{
    display: block;
}

.footer-wrapper .contact-dets ul li {
    border-right: none;
    padding: 0;
    margin-bottom: 18px;
}
.footer-wrapper .contact-dets ul li a {
    color: var(--text-color);
    font-size: 16px;
    font-family: var(--font-text);
}
.footer-wrapper .social-icon {
    display: flex;
 gap: 16px;
 flex-wrap: wrap;
}
.footer-wrapper .social-icon a{
    font-size: 24px;
}
.copyright{
    border-top: 1px solid var(--secondary-color);
    padding: 4px 0;
}

.copyright p{
    margin: 0;
}

/* --breadcrumb-start--  */

.breadcrumb{
    padding-top: 110px; 
    padding-bottom: 30px;
    margin: 0;
    background: var(--gradient-color);
}


.breadcrumb .items li{
    font-size: 14px;
    font-weight: 450;
    margin: 0px 15px 0 0;
    display: inline;
}
.breadcrumb .items li .active{
    color: var(--primary-color);
}


/* --breadcrumb-end--  */

/* ----hero-section-start-----  */

.home-banner-section{
    padding-top: 100px;
    /* min-height: calc(100vh + 50px); */
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gradient-color);
    padding-bottom: 70px;
    position: relative;
}
.podcast-text-none{
    display: inline;
}
.background-hero{
    position: absolute;
    top: 112px;
    left: 0px;
    background-position: top;

    width: 100%;
    height: 700px;
    
}

.podcast-btn{
    color: var(--secondary-color);
    font-size: 16px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
}
.podcast-btn i{
    color: var(--color-white);
    font-size: 10px;
}
.podcast-btn span{
    border-radius: 50%;
    padding: 2px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    margin-right: 5px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
   /* background-color: rgba(140, 82, 255, 0.14); */
}

.banner-caption{
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.banner-caption .pera{
    font-size: 16px;
}

.banner-img video,
.banner-img img{
box-shadow: var(--box-shadow);
}
.text-banner-badge{
    font-size: 20px;
}


/* --------about-section---------  */


.about-img{
    margin-top: -110px;
    position: relative;
    z-index: 1;
    background-color: white;
}
.about-img>img{
    width: 100%;
}


/* -------our-philosophy----  */

.our-philosophy{
    background-color: var(--background-color);
}
.our-philosophy .right-img{
    max-height: 300px;
    overflow: hidden;
}
.our-philosophy .right-img img{
   width: 100%;
   object-fit: cover;
}

/* -------our-services-----  */

.our-services{
    width: 100%;
}

.down-arrow{
    margin-top: 20px;
    font-size: 48px;
    display: block;
    text-align: center;
    color: var(--text-color);
}

.service-item{
    height: 100%;
    border: 2px solid rgb(175, 175, 175);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 40px;
}

.box-details {
    margin-top: -130px;
    padding: 30px 15px;
    border-radius: 15px;
    margin-bottom: 30px;
    border: 1px solid rgb(175, 175, 175);
    
}
.box-details .pera{
    margin-bottom: 30px;
}

.Presents{
    font-family: var(--font-text);
color: #ba8e34;
margin-bottom: 30px;
font-size: 14px;
}
.Presents .stylish{
    font-family: var(--font-stylish);
color: var(--primary-color);
font-size: 36px;
font-weight: 550;
}

.title{
    color: var(--secondary-color);
    font-size: 36px;
    margin-bottom: 20px;
    font-family: var(--font-heading);
}
.title .stylish{
    font-size: 42px;
    font-weight: normal;
    font-family: var(--font-stylish);
}
.sub-title{
    font-size: 20px;
   font-family: var(--text-color);
   color: var(--text-color) ;
   text-transform: uppercase;
}

.gradient-one{
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(181, 204, 233) 97%);
}
.gradient-two{
    background: linear-gradient(331deg, rgba(255,255,255,1) 0%, rgb(248, 208, 247) 97%);
    
}
.gradient-three{
    background: linear-gradient(84deg, rgba(255,255,255,1) 0%, rgb(210, 196, 236) 97%);
}
.gradient-four{
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(222, 240, 243) 97%);
}

.padding-top-item{
    height: 100%;
    padding-top: 140px;
}


/* ------transformation-----------  */


.transformation{
    width: 100%;
   background-color: var(--background-color);
}

.tr-items{
    position: relative;
    z-index: 1;
    padding-top: 100px;
}

.tr-img-bg{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    opacity: .3;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.img-tr{
    margin: auto;
    max-width: 250px;
}
.img-tr img{
    width: 100%;
}

.tr-box{
display: flex;
flex-direction: column;
gap: 10px;
margin-bottom: 70px;
}
.tr-box.left{
justify-content: start;
align-items: start;
}
.tr-box.right{
justify-content: end;
align-items: end;
}

.tr-box .icon{
width: 150px;
}

.tr-box .icon>img{
width: 100%;
}
.tr-box .title-tr{
color: var(--primary-color);
font-size: 20px;
margin: 0;
}
.tr-box.right .pera{
text-align: end;
}


/* ---testimonial-------  */

.testimonial{
overflow: hidden;
}
.testimonial .img-testimonial{
max-width: 400px;
margin: auto;
max-height: 516px;
box-shadow: var(--box-shadow);
overflow: hidden;
}
.testimonial .img-testimonial img{
width: 100%;
border-radius: 4px;
}

.testimonial-slide{
    margin-top: 40px;
    position: relative;
    margin-right: -150px;
    z-index: 1;
    height: 100%;
}
.testimonial .owl-nav{
    position: absolute;
    z-index: 1;
    bottom: 40px;
    right: 40px;
}
.testimonial  .owl-carousel .owl-nav button.owl-prev,
.testimonial .owl-carousel .owl-nav button.owl-next,
.testimonial  .owl-carousel button.owl-dot{
   background-color: transparent;
}
.testimonial  .owl-carousel .owl-nav button.owl-prev:hover,
.testimonial .owl-carousel .owl-nav button.owl-next:hover,
.testimonial  .owl-carousel button.owl-dot:hover{
   opacity: .9;
   background-color: transparent;
   color: white;
}
.owl-theme .owl-nav [class*='owl-']{
    background-color: transparent;
    color: var(--secondary-color);
    font-size: 18px;
    transition: .2s all ease-in;
}
.owl-theme .owl-nav [class*='owl-']:hover{
    background-color: var(--secondary-color);
    color: var(--color-white);
}
.testimonial-slide .item-testimonial{
    padding: 40px;
    min-height: 238px;
    border-radius: 4px;
    background-color: var(--background-color);
}

.pera-testimonial{
    font-weight: 600;
}

.user-box{
    display: flex;
align-items:start;
    gap: 10px;
    margin-top: 10px;
}

.img-user{
    width: 70px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}
.img-user img{
    width: 100%;
}

.user-name{
    color: var(--primary-color);
    font-size: 18px;
    margin: 0;
}
.destination{
    font-size: 14px;
    margin: 0;
}

.rating-icon{
    color: #f8ca59;
    font-size: 14px;
}

    
    /* -----articals-start---  */
    .artical-box{
        height: 100%;
        box-shadow: var(--box-shadow);
        border-radius: 8px;
        overflow: hidden;
        display: inline-block;
    }
    
    .artical-box .img-artical{
        width: 100%;
        max-height: 200px;
        height: 100%;
        overflow: hidden;
    }
    
    .artical-box .img-artical img{
        object-fit: cover;
        width: 100%;
        object-position: top;
        transition: .3s;
    }
    .artical-box .img-artical img:hover{
        transform: scale(1.2) rotate(-5deg);
    }
    
    .content-artical{
        padding: 10px 15px 20px 15px;
    }
    .content-artical .desc{
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .detail{
        color: var(--primary-color);
        font-size: 13px;
    }
    
    .title-artical{
        margin-top: 10px;
        font-size: 24px;
        color: #302e2e;
        font-weight: 500;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    
    
    
    /* -----articals-end---  */


/* ----inner-banner-section-start-----  */

.inner-banner-section{
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gradient-color);
    padding-bottom: 90px;
    position: relative;
}

/* ---step-process---- */

.step-process{
    background-color: var(--background-color);
}
.step-process .sub_heading{
   font-size: 26px;
   color: var(--text-color);
}
.border-theme{
    border-color: var(--secondary-color) !important;
}

.steps-container{
    position: relative;
    
}
.step .title{
    color: rgb(61, 61, 61);
    font-family: var(--font-heading);
    font-size: 28px;
    margin-bottom: 10px;
}

.step{
    padding-bottom: 30px;
    position: relative;
    padding-left: 140px;
    z-index: 1;
}

.step::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 21px;
    transform: translateX(-50%);
    width: 2px;
    background-color: var(--secondary-color);
    z-index: -1;
    height: 100%;
}
.step:last-child::after{
    display: none;
}
.step-number{
    position: absolute;
    background-color: var(--color-white);
    left: 0px;
    border: 2px solid var(--secondary-color);
    width: 42px;
    border-radius: 50%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: var(--secondary-color);
    transition: .2s;
   
}
.step:hover .step-number{
    background-color: var(--secondary-color); cursor: pointer;
    color: white;
}

/* ----why-choose-us----  */

.why-choose{
    background-color: var(--background-color);
    min-height: 400px;
    overflow: hidden;
}

.why-use-img{
    width: 100%;
    object-position: top;
    max-height: 250px;
}

.item-why-us{
    display: flex;
    gap: 20px;
    
}
.item-why-us{
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
}
.item-why-us .icon-why{
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
}
.item-why-us .icon-why img{
    width: 100%;
    object-fit: contain;
}
.item-why-us.one{
    padding-top: 111px;
}
.item-why-us.three{
    padding-top: 30px;
    padding-bottom: 0;
}
.item-why-us-right{
    padding-top: 35px;
    padding-left: 20px;
}

/* ----session------  */

.session{
    background-color: var(--background-color);
}

 .list-with-check li{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}
 .list-with-check{
    margin: 20px 0;
}
.list-with-check li i{
    width: 22px;
    height: 22px;
    font-size: 14px;
    background-color: var(--secondary-color);
    padding: 5px;
    color: var(--color-white);
    border-radius: 50%;
}

.session .right-img{
    max-height: 400px;
}
.session .right-img{
   width: 100%;
   object-fit: contain;
   max-height: 400px;

}

/* ----services--- */



/* ------subscribe------ */

/* .theme_input{
    all: unset;
    outline: none;
    border-radius: 4px;
    padding: 0px 20px;
    min-width: 204px;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    min-height: 34px;
}
.theme_input:focus{
   box-shadow: var(--box-shadow);
}
::placeholder {
    color: var(--secondary-color);
  }
  
  ::-ms-input-placeholder { 
    color: var(--secondary-color);
  } */

.subscribe{
    background-image: linear-gradient(to right, rgba(222, 210, 249, 0.9) 10%, rgba(219, 202, 255, 0.6) 100%), url('./../../../public/assets/images/service-bg-bottom.png');
}
.subscribe-box{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
    gap: 10px;
}

/* -----content-service------- */
.single-sevice-container .img-right{
margin: auto;
max-height: 800px;
overflow: hidden;
}
.single-sevice-container .img-right.top{
border-left: 10px solid var(--color-white);
border-bottom: 10px solid var(--color-white);
margin-bottom: -80px;
position: relative;
z-index: 1;
}
.single-sevice-container .img-right img{
width: 100%;

}
.content-service{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    max-width: 500px;
}
.content-service.two{
   padding-top: 80px;
   margin-left: auto;
}

/* ---podcast--items-------  */

.podcast-item{
padding: 60px 0;
}
.podcast-item:nth-of-type(odd){
background-color: var(--background-color);
}
.podcast-item:first-child{
    background: var(--gradient-color);
}

.podcast-item:nth-of-type(even) .row>div{
order: 2;
}
.podcast-item:nth-of-type(even) .row>div + div{
order: 1;
}
.podcast-item:nth-of-type(even) .banner-caption{
    padding-left: 50px;
    padding-right: 0px;
}

/* --pagination----  */

.pagination{
   display: flex;
   padding-bottom: 60px;
   justify-content: center;
   align-items: center;
   gap: 15px;
}
.pagination .pagination-items{
   display: flex;
   justify-content: center;
   gap: 10px;
}
.pagination .item:hover,
.pagination .item.active{
   background: var(--secondary-color);
   color: white;
}

.pagination .item{
   width: 24px;
   height: 24px;
   transition: .2s;
   border: 1px solid gray;
   border-radius: 2px;
   color: gray;
   display: flex;
   justify-content: center;
   align-items: center;
}
.pagination .item.active{
   border: 1px solid var(--secondary-color);
   color: white;
   background-color: var(--secondary-color);
}
.pagination .icon{
   color: var(--secondary-color);
   transition: .2s;
}
.pagination .icon.disabled{
   color: gray;
   transition: .2s;
}

.pagination .icon.next:hover{
   transform: translateX(5px);
   color: var(--secondary-color);
}
.pagination .icon.pre:hover{
   transform: translateX(-5px);
   color: var(--secondary-color);
}
.pagination .icon.disabled:hover{
    color: gray;
    transform: translateX(0);
 }

/* ------podcast-detail-page-------  */

.podcast-detail{
    background: var(--gradient-color);
}

.detail-podcast-video{
    max-width: 700px;
    padding: 30px 0 40px 0;
    margin: auto;
}
.detail-podcast-video video{
   box-shadow: var(--box-shadow);
}

.podcast-detail-content .img-right.top{
    border-left: 10px solid var(--color-white);
    border-bottom: 10px solid var(--color-white);
    position: relative;
    z-index: 1;
    margin: auto;
    max-height: 800px;
    overflow: hidden;
}
.podcast-detail-content.img-right img{
width: 100%;

}

/* ---blog-sidebar-------  */

.blog-sidebar{
    background-color: var(--background-color);
    position: sticky;
    border-radius: 8px;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.feed{
    background-color: var(--color-white);
    padding: 15px;
    border-radius: 12px;
}
.feed .heading{
    font-size: 20px;
    margin-bottom: 15px;
    color: var(--primary-color);
    font-family: var(--font-heading);
}

.searchbar{
    position: relative;
}
.searchbar .search-input{
    border: 1px solid gray;
    border-radius: 50px;
    width: 100%;
    padding: 10px 40px 10px 20px;
    color: #000;

}
.searchbar .search-input::placeholder {
    color: gray;
  }
.searchbar .search-input:focus{
    outline: none;
    border-color: #3f3f3f;
    box-shadow: var(--box-shadow);


}
.searchbar .icon-submit{
    position: absolute;
    right:20px;
    top: 50%;
    color: gray;
    cursor: pointer;
    transform: translateY(-50%);
}

.about-feed .info{

    display: flex;
    gap: 10px;

}
.about-feed .info .name{
    font-size: 16px;
    font-family: var(--font-heading);
    margin: 0;
}
.about-feed .info .desination{
    font-size: 12px;
    margin: 0;
    color: var(--text-color);
    font-family: var(--font-text);
}
.about-feed .info .img{
    flex-shrink: 0;
    border-radius: 50%;
    width: 36px;
    overflow: hidden;
    height: 36px;
}
.about-feed .info .img img{
    width: 100%;
}
.about-feed .social{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}
.about-feed .social li i{
    font-size: 20px;
    margin-right: 10px;
    color: var(--secondary-color);
}
.categoty-list{

display: flex;
gap: 10px;
flex-wrap: wrap;

}
.categoty-list li{

padding: 2px 12px;
border: 1px solid gray;
border-radius: 20px;

}

.categoty-list li{

padding: 2px 12px;
border: 1px solid gray;
border-radius: 20px;

}
.categoty-list li:hover{

color: var(--color-white);
background-color: var(--primary-color);
border: 1px solid var(--color-white);

}
.categoty-list li:hover a{
transition: .1s;
color: var(--color-white);

}
.categoty-list li:nth-child(2n+1) {
border: 1px solid var(--primary-color);
color: var(--primary-color);
}
.categoty-list li:nth-child(3n+1) {
border: 1px solid var(--secondary-color);
color: var(--secondary-color);
}
.categoty-list li a{
color: inherit;
}

.PopularPost-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.PopularPost-list .item{
    display: flex;
    gap: 10px;
}
.PopularPost-list .item .img{
    width: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    height: 50px;
    overflow: hidden;
}
.PopularPost-list .item .img img{
    width: 100%;
}
.PopularPost-list .item .content{
    font-size: 14px;
    line-height: 20px;
}
.PopularPost-list .item .content:hover{
    color: gray;
}

/* ---blog-detail-page---  */

.blog-detail-top{
    padding-bottom: 10px;
    background: var(--gradient-color);
}

.blog-detail-top .social{
    display: flex;
    margin: 25px 0;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.blog-detail-top .details{
   color: var(--text-color);
}
.blog-detail-top .social li a{
   color: #000;
   font-size: 24px;
}
.blog-detail-top .blog-feature-img{
   width: 100%;
   border-radius: 8px;
   max-height: 700px;
   box-shadow: var(--box-shadow);
   overflow: hidden;
}
.blog-detail-top .blog-feature-img img{
   width: 100%;
   object-fit: cover;
}

.blog-detail-content h1,
.blog-detail-content h2,
.blog-detail-content h3,
.blog-detail-content h4,
.blog-detail-content h5,
.blog-detail-content h6{
    color: var(--primary-color);
    font-family: var(--font-heading);
}
.blog-detail-content ol{
    /* all: unset; */
    list-style: decimal;
    padding: 0;
    padding-left: 15px;
    color: var(--primary-color);
}
.blog-detail-content p,
.blog-detail-content span{
    color: var(--text-color);
    font-size: 14px;
}


/* ---------contact-us-page-------  */
.contact-contaier{
    background: var(--gradient-color);
    padding-bottom: 0;
}
.content-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.contact-form{
    border: 1px solid gray;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    z-index: 1;
    max-width: 500px;
    background-color: white;
    margin-bottom: -120px;
    margin-left: auto;
}
.contact-form .sec_heading-stylish{
    padding: 10px 0;
    border-bottom: 1px solid gray;
}
.contact-form .fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact-form .fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* .contact-form textarea,
.contact-form .theme_input{
    all: unset;
    outline: none;
    border-radius: 4px;
    padding: 4px 10px;
    min-width: 204px;
    color: var(--primary-color);
    border: 1px solid gray;
    min-height: 34px;
}
.contact-form .theme_input:focus{
    border: 1px solid var(--primary-color);
   box-shadow: var(--box-shadow);
}
.contact-form ::placeholder {
    color: gray;
  }
.contact-form .theme_input:focus::placeholder {
    color: var(--primary-color);
  } */
.contact-form .theme_btn{
   min-height: 40px;
  }
  
 .contact-form ::-ms-input-placeholder { /* Edge 12 -18 */
    color: gray;
  }

  .contact-bg-center{
    min-height: 240px;
    max-height: 240px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, #8c52ff 97%);
  }
  .contact-bg-center .img{
    width: 100%;
    max-width: 400px;
    object-fit: contain;
    position: absolute;
    right: 50%;
    z-index: 2;
  }

  .we-are-here .box-contact{
    border: 1px solid gray;
    display: flex;
    border-radius: 12px;
    align-items: center;
    max-width: 500px;
    padding: 30px 35px;
    gap: 20px;
    transition: .2s;
  }
  .we-are-here .box-contact:hover{
    box-shadow: var(--box-shadow);
  }
  .we-are-here .box-contact .icon{
    flex-shrink: 0;
  }
  .we-are-here .box-contact .icon i{
    font-size: 48px;
    color: var(--secondary-color);
  }
  .we-are-here .box-contact .title-box{
    font-size: 16px;
    font-family: var(--font-heading);
    margin-bottom: 0;
  }
  .we-are-here .box-contact .pera{
    margin: 0;
    line-height: 18px;
  }

  /* ------product-design-------- */

  .product-box{
    display: block;
    box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.179);
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
  }
  .product-box:hover{
    box-shadow: var(--box-shadow);
    transform: translateY(-10px);
  }
  .product-box .product-img{
    max-height: 300px;
    display: flex;
    background-color: var(--background-color);
    justify-content: center;
    overflow: hidden;
  }
  .product-box .product-img img{
    object-fit: contain;
    transition: ease-in .4s;
  }
  .product-box .product-img img:hover{
    transform: scale(1.1);
  }
  .product-box .product-content{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .product-box .product-title{
    font-size: 18px;
    font-family: var(--font-heading);
    color: var(--text-color);
    margin: 0;
    min-height: 44px;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .product-box .product-rating{
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .product-rate{
    font-weight: 500;
  }
  .product-rating i{
   color: #f8ca59;
   font-size: 12px;
  }
  .product-rating .rating-number{
    margin-left: 4px;
    font-size: 12px;
  }
  .product-box .product-rate{
    font-size: 14px;

  }

  /* ----shop-sidebar-----  */

  .product-category{ 
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .product-category .category-item{ 
    display: flex;
    gap: 10px;
  }
  .product-category .category-item label{ 
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    font-family: var(--font-text);
  }


  /* ------product-details------ */

  .product-details{
    max-width: 550px;
    margin: auto;
  }
  
.product-img-lg{
    min-height: 500px;
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-height: 550px;
}
.product-img-lg{
    width: 100%;
    object-fit: contain;
}

.product-sub-imgs{
    margin-top: 8px;
    display: flex;
    gap: 4px;
}
.product-sub-imgs .img-sub{
    border: 1px solid #dcdcdc;
    aspect-ratio: 1/1;
    cursor: pointer;
    border-radius: 8px;
}
.product-sub-imgs .img-sub img:hover{
    transition: .3s;
    transform: scale(1.04);
}
.product-sub-imgs .img-sub.active{
    border: 1px solid var(--primary-color);
}
.product-sub-imgs .img-sub img{
    object-fit: contain;
}

.product-category{

    font-size: 16px;
    font-family: var(--font-text);
    font-weight: 500;
    color: var(--secondary-color);

}

.prodcut-name{
    font-size: 46px;
    color: var(--font-text);
    letter-spacing: 1px;
    font-family: var(--font-heading);
    font-weight: 600;
    margin: 20px 0;
}

.video-thumbnail{
    margin: 20px 0 0;
}
.video-thumbnail video{
    border-radius: 4px;
    aspect-ratio: 16/9;
    box-shadow: var(--box-shadow);
}

.product-count{
    border: 1px solid gray;
    padding: 4px 8px;
    margin: 20px 0 0;
    display: inline-flex;
    gap: 4px;
    align-items: center;
    border-radius: 5px;
}
.product-count .product-plus,
.product-count .product-minus{
   color: var(--text-color);
   font-weight: bold;
   font-size: 16px;
    padding: 4px 8px;
    transition: .2s;
    border-radius: 4px;
}
.product-count .product-plus:hover,
.product-count .product-minus:hover{
    cursor: pointer;
    background-color: var(--primary-color);
   color: var(--color-white);
   font-weight: bold;
   font-size: 16px;
    padding: 4px 8px;
    border-radius: 4px;
}
.product-count .product-count{
    all: unset;
    font-family: var(--font-text);
    padding: 0px 0px 0px 18px;
    max-width: 30px;
    border-radius: 4px;
}

.video-thumbnail{
    margin: 20px 0 0;
}
.video-thumbnail video{
    border-radius: 4px;
    aspect-ratio: 16/9;
    box-shadow: var(--box-shadow);
}

.product-prize{
    margin: 30px 0;
    font-size: 24px;
}



/* login-form  */

.form_container {
    width: 100%;
    padding: 30px 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* background: linear-gradient(to right, #45575b8b 50%, #45575b42 100%),
      url("./assets/images/bg-form_login.jpg"); */
    background: url("../../../public/assets/images/login-background.png");
    background-size: cover;
    transition: 0.2s;
  }
  .form_container .form_main {
    width: 70%;
    margin: auto;
  }
  .requied{
    color: rgba(255, 0, 0, 0.922);
  }
  .form_container .form_main .heading::after {
    display: none;
  }
  .form_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 583px;
    position: relative;
    background-color: #fff;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    overflow: hidden;
    transition: 0.3s;
  }
  /* .form_bg::before {
    content: "";
    position: absolute;
    background-color: rgba(140, 82, 255, 0.306);
    backdrop-filter: blur(5px);
    bottom: 80%;
    border-radius: 50%;
    z-index: -1;
    left: -200px;
    width: 300px;
    height: 300px;
  }
  .form_bg::after {
    content: "";
    z-index: -1;
    position: absolute;
    background-color: rgba(140, 82, 255, 0.306);
    backdrop-filter: blur(5px);
    top: 80%;
    border-radius: 50%;
    right: -200px;
    width: 300px;
    height: 300px;
  } */
  .form_container .logo {
    width: 120px;
  }
  .form_container .logo img {
    width: 100%;
  }
  .form_container form {
    padding: 0px 20px;
  }
  
  .form_container form a {
    color: var(--secondary-color);
    /* text-decoration: none; */
    transition: all .3s;
  }
  .form_container form a:hover{
    color: var(--secondary-color);
    text-decoration: underline;
    color: #090c0c !important;
  
  }
  .form_container form .row {
    padding: 4px 0;
  }
  
  .form_container .form span {
    font-size: 12px !important;
  }
  
  .form_container .form .heading h2 {
    font-size: 42px;
    margin-bottom: 0px;
    color: var(--primary-color);
    font-family: var(--font-stylish);
  }
  .form_container .form .heading p {
    font-size: 12px !important;
    color: var(--primary-color);
    font-family: var(--font-text);
  }
  .form_container .logo-auth {
    width: 110px;
    margin: auto;
  }
  .form_container .logo-auth img{
    width: 100%;
    object-fit: contain;
  }
  
   label {
    color: #000;
    font-size: 14px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--font-heading);
  }
  .form-label{
    margin-bottom: 0;
  }
   input[type=text],
   input[type=email],
   input[type=number],
   input[type=textarea],
   input[type=tel],
   input[type=password],
   select,
   /* input[type=submit], */
   textarea {
    font-size: 0.8rem !important;
    width: 100%;
    color: rgba(140, 82, 255) !important;
    padding: 6px 10px;
    font-weight: 600;
    border-radius: 4px !important;
    background-color: rgba(140, 82, 255, 0.065);
    border: 1px solid rgba(140, 82, 255, 0.633);
    outline: none;
    position: relative;
  }
  select {
    border-color: rgba(140, 82, 255, 0.633) !important;
    /* background-color: rgba(140, 82, 255, 0.633) !important; */
  }
  .submit_btn {
    width: 100%;
    padding: 5px;
    border-radius: 0px !important;
    border: none;
    justify-content: center;
    outline: none !important;
    background-color: var(--background-color) !important;
    color: #fff;
  }
  .submit_btn:hover {
    letter-spacing: 1.1px;
    background-color: var(--primary-color) !important;
  }
  
  .form-select,
  .form-control:focus {
    box-shadow: none !important;
  }
  
   select:focus,
  textarea:focus,
   input[type="text"]:focus,
   input[type="email"]:focus,
   input[type="number"]:focus,
   input[type="textarea"]:focus,
   input[type="tel"]:focus,
   input[type="password"]:focus {
    box-shadow: 0px 0px 1px 0px rgba(140, 82, 255, 0.565);
    border: 1px solid rgba(140, 82, 255, 0.829) !important;
    background-color: var(--color-white) !important;
  }
  /* input:not(:focus),
  textarea:not(:focus){
    color: white;
  } */
   textarea:focus::placeholder,
   input:focus::placeholder {
    visibility: hidden;
    color: #38414249 !important;
  }
   input::placeholder,
   textarea::placeholder {
    color: #38414298 !important;
  }
  
  .form_container .form-img {
    width: 100%;
    height: 100%;
    max-height: 583px;
    margin: auto;
    overflow: hidden;
    background-color: var(--primary-color);
  }
  .form_container .row_form {
    border: 1px solid #a9bbb699;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    overflow: hidden;
    width: 70%;
    margin: auto;
  }
  .form_container .form-img img {
    width: 100%;
    object-position: top;
    height: 100%;
    /* mix-blend-mode: overlay; */
  }
  
  /* ------checkbox start--------- */
  .sb-checkbox__input {
    display: none;
  }
  .sb-checkbox__input:checked + .sb-checkbox__label:after {
    opacity: 1;
  }
  .sb-checkbox__label {
    font-size: 12px !important;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .sb-checkbox__label:before {
    content: "";
    width: 13px;
    height: 13px;
    border: 2px solid;
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    top: 8%;
    left: -20px;
  }
  .sb-checkbox__label:after {
    content: "\2714";
    font-family: "Material Icons";
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    width: 13px;
    height: 13px;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 8%;
    left: -20px;
    text-align: center;
    opacity: 0;
    transition: 0.2s opacity;
  }
  .sb-checkbox__label:before {
    border-color: var(--primary-color);
  }
  .sb-checkbox__label:after {
    background-color: var(--primary-color);
    color: #fff;
  }
  /* -----checbox-end---- */



  /* ----service-detail-page------  */
  
  .box-service-details{
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .servie-detail-img{
    margin-top: -110px;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    border: 6px solid var(--color-white);
    background-color: white;
}
.servie-detail-img>img{
    width: 100%;
}

/* ----page-not-found------ */

.page-not-found{
    padding-bottom: 192px;
    background: var(--gradient-color);
    min-height: calc(100vh - 162px);
}
.error{
    font-size: 150px;
    color: var(--secondary-color);
    font-family: var(--font-heading);
    text-shadow: 
      1px 1px 1px var(--secondary-color),    
      2px 2px 1px var(--secondary-color),
      3px 3px 1px var(--secondary-color),
      4px 4px 1px var(--secondary-color),
      5px 5px 1px var(--secondary-color),
      6px 6px 1px var(--secondary-color),
      7px 7px 1px var(--secondary-color),
      8px 8px 1px var(--secondary-color),
      25px 25px 8px rgba(0,0,0, 0.2);
  }
  
  .page{
    margin: 30px 0;
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-text);
    color: var(--text-color);
  }

  /* ----blog-detail-comment------- */
  .comment-box {
    background: #fff;
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.165), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: block;
}

.comment-box .block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.comment-box .user-name {
    font-family: var(--font-heading);
}


.comment-box .writing {
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
    padding: 12px;
}
.comment-box .writing .textarea {
    width: 100%;
    font-family: "Inter";
    color: var(--text-color);
    height: 50px;
    overflow-y: auto;
    appearance: none;
    border: 0;
    outline: 0;
    resize: none;
    font-size: 16px;
    line-height: 24px;
}
.comment-box .writing:focus-within {
    border: 1px solid #0085ff;
    box-shadow: 0px 0px 2px 2px rgba(0, 133, 255, 0.15);
}
.comment-box .writing .footer-comment {
    margin-top: 12px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e8e8e8;
}
.comment-box .writing .footer-comment .text-format {
    display: flex;
    align-items: center;
    gap: 12px;
}
.comment-box .comment {
    display: grid;
    gap: 8px;
    padding: 10px 0;
    border-bottom: 1px solid #e7e6e6;
}
.comment-box .comment .user-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-box .comment .user-banner .user {
    gap: 8px;
    align-items: center;
    display: flex;
}
.comment-box .comment .user-banner .user .avatar {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    position: relative;
    border-radius: 100px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}
.comment-box .comment .user-banner .user .avatar img {
    max-width: 100%;
    border-radius: 50%;
}

.comment-box .tag {
   font-size: 14px;
   color: var(--secondary-color);
}
.comment-box .comment .user-banner .user .avatar .stat.green {
    background: #00ba34;
}
.comment-box .comment .user-banner .user .avatar .stat.grey {
    background: #969696;
}
.comment-box .comment .footer-comment {
    gap: 12px;
    display: flex;
    align-items: center;
}

.comment-box .comment .footer-comment .divider {
    height: 12px;
    width: 1px;
    background: #d8d8d8;
}
.comment-box .comment:not(.comment:first-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
}
.comment-box .comment + .comment {
    padding-top: 12px;
}
.comment-box .comment.reply .user-banner, 
.comment-box .comment.reply .content, 
.comment-box .comment.reply .footer-comment {
    margin-left: 32px;
}
.comment-box .group-radio {
    position: relative;
    display: flex;
    user-select: none;
    align-items: stretch;
}
.comment-box .group-radio .button-radio {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}
.comment-box .group-radio .button-radio label {
    cursor: pointer;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    height: 28px;
    align-items: center;
    line-height: 28px;
    transition: 0.2s ease;
}
.comment-box .group-radio .button-radio:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}
.comment-box .group-radio .button-radio:last-child {
    border-top-right-radius: 8px;
    border-right: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-bottom-right-radius: 8px;
}
.comment-box .group-radio .button-radio input[type="radio"] {
    display: none;
}
.comment-box .group-radio .button-radio input[type="radio"]:checked + label {
    background: #edecec;
}
.comment-box .group-radio .divider {
    width: 1px;
    background: #e8e8e8;
}

.comment-box .btn.dropdown {
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 26px;
    height: 26px;
}
.comment-box .btn.dropdown:hover {
    background-color: #eee;
}
.comment-box p {
    line-height: 24px;
}
.comment-box p a.tagged-user {
    display: inline-flex;
    padding: 2px 8px;
    background: #e5f3ff;
    border-radius: 256px;
    color: #0085ff;
}
.comment-box .is-mute {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #969696;
}
.comment-box a {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #1c1c1c;
    text-decoration: none;
    transition: opacity 0.15s ease-in-out;
}
.comment-box a:hover {
    opacity: 0.7;
}

.comment-box .group-button {
    display: flex;
    gap: 16px;
}

/* --welcome-new-page--- */

.welcome-top{
    background: var(--gradient-color);
}
.welcome-top .Presents {
    font-size: 26px;
}
.welcome-top .Presents span{
    font-size: 46px;
}
.welcome-top .title .stylish {
    font-size: 90px;
}
.welcome-top .title {
    font-size: 75px;
    color: var(--primary-color);
}
.welcome-top .sub_heading{
    font-size: 36px;
}




.img-welcome-ready{
    width: 100%;
    max-height: 400px;
    position: relative;
    z-index: 1;
    margin-top: 30px;
    overflow: hidden;
    border: 5px solid var(--background-color);
    border-radius: 4px;
}
.img-welcome-ready img{
    width: 100%;
    object-fit: contain;
    height: 100%;
}
.ready-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: start;
}

.unique-program-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}
.unique-program-content .pera{
   font-size: 16px;
   font-weight: 450px;
}

.img-unique-program{
    max-width: 800px;
    width: 100%;
    min-height: 300px;
    border: 5px solid rgb(241, 241, 241);
    margin-bottom: -100px;
    border-radius: 4px;
    overflow: hidden;
}
.img-unique-program img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.session-information.sec_padding{
    padding: 100px 0px 0;
    background-color: var(--background-color);
}

.session-information-box{
    background-color: var(--color-white);
    border-radius: 4px;
    max-width: 1100px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 40px;
    border: 1px solid var(--primary-color);
    box-shadow: var(--box-shadow);
    margin-bottom: -50px;
}
.session-information-box .pera{
    font-size: 15px;
    font-weight: 450;
}

.welcome-register-form.contact-form{
    margin: auto;
    background: none;
    border: none;
    box-shadow: none;
    margin-bottom: 0px;
}
.welcome-register-form.contact-form input{
    margin-bottom: 0 !important;
}
.welcome-register-form.contact-form .input-field .required{
    color: rgb(221, 19, 19);
    font-size: 13px;
}
.content-bottom-about{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.bottom-section-welcome{
    padding-top: 160px;
}
.michele-img{
    width: 100%;
    max-width: 300px;
}
.right-side-form{
    padding-top: 100px;
}
.michele-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* --hosted-container---  */

.hosted-container{
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.logo-container{

}

.hosted-logo{
    width: 100%;
    max-width: 330px;
    vertical-align: middle;
}

.hosted-by-title{
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-weight: 400;
    color: #ba8e34;
    font-family: var(--font-heading);
}
.hosted-container .pera{
    font-size: 16px;
    margin: 0;
    font-family: var(--font-text);
    color: var(--text-color);
}

/* ---user-profile----  */

/* ---------profile-edit----------- */


.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 10px auto;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    z-index: 1;
    right: 36%;
    bottom: -10%;
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: var(--background-color);
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
  .avatar-upload .avatar-edit input + label:hover::after {
    color: white;
  }
  .avatar-upload .avatar-edit input + label:after {
    content: "\f030";
    font-family: 'FontAwesome';
    color: var(--white-color);
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-upload .avatar-preview {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    border: 1px dashed var(--primary-color);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview >  img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  

.input-group{
    position: relative;
}

.icon-show-hide{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--secondary-color);
}
.profile-sec_heading{
    color: var(--primary-color);
    font-size: 20px;
    font-family: var(--font-heading);
}

.img-user-box{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
}
.img-user-box img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.info-container, .card-body{
    font-family: var(--font-text);
    font-size: 14px;
}
.card{
    border: 1px solid #e6e6e6;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}


.user-name{
    font-size: 40px;
    font-weight: 600;
    font-family: var(--font-stylish);
}
.nav-user-profile .nav-link{
    color: #000;
    font-family: var(--font-heading);
}
.nav-user-profile .nav-link{
    padding: 6px 20px;
}
.nav-user-profile .nav-link.active{
    color: #fff;
    background-color: var(--primary-color);
}
.card-header {
    background: var(--gradient-color);
    font-family: var(--font-heading);
    color: var(--primary-color);
}








